<template>
  <v-container class="px-10" fluid>
    <v-popup-loading :isLoading="isLoadingBranch" />
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      subTitle="Are you sure you want to remove this Department? This action will remove content in both Thai and English."
      :close="closeDialog"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDialog"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isDeleteError"
      title="Department cannot be deleted."
      subTitle="This department cannot be deleted because there is Doctor under this branch."
      :close="closeDeleteErrorDialog"
      headline-color="black"
      :confirm="closeDeleteErrorDialog"
      icon=""
      buttonCancel="OK"
    ></alert-confirm>
    <v-preview
      :show="isPreview"
      :closePreivew="closePreview"
      :data="departmentObject"
    />
    <edit-order-dialog
      :show="isEditOrder"
      :data="editOrderObject"
      :close="closeEditOrder"
      @save="saveEditOrder"
    ></edit-order-dialog>
    <add-highlight-popup
      :show="showAddHighlightPopup"
      @cancel="showAddHighlightPopup = false"
      @confirm="saveHighlight"
    ></add-highlight-popup>

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Department</h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="create-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Create Department
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title :class="{'mb-0': true, 'pb-0': isSelectedBranch}">
        <v-row>
          <v-col cols="12" sm="4" class="mt-0 pt-0 pb-0">
            <single-selected-branch
              outlined
              required
              dense
              title="Branch"
              :branch="branches"
              :selectedBranch="selectedBranch"
              :syncData="changedSelectedBranch"
              :rule="v => !!v || 'Please select branch'"
            ></single-selected-branch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pt-0 pb-2" v-if="showSearchAndFilter">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12" sm="4" t-data="search-panel">
            <search
              :key="componentKey"
              :search="filters.searchPhase"
              label="Search by Title"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" sm="6"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center pt-0 pb-0"
            t-data="filter-panel"
          >
            <v-btn
              style="margin-right: 16px;"
              id="hightlight-btn"
              :disabled="isLoading"
              v-if="hasPermCreate()"
              color="primary600Color"
              @click="addHighLight"
              class="white-text-button button-bold"
              t-data="add-hightlight-btn"
            >
              Add Highlight
            </v-btn>
            <filter-dropdown
              :key="componentKey"
              @apply="handleApplyFilter"
            ></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs
        v-if="isSelectedBranch"
        v-model="tab"
        class="status-tab fix-height-tab body-regular"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
        @change="fetchByTab"
      >
        <v-tab class="fix-width-tab" v-for="item in tabItems" :key="item">
          {{ item }}
        </v-tab>
        <v-tab-item>
          <v-layout fluid v-resize="onResize">
            <v-data-table
              :loading="isLoading"
              :options.sync="options"
              :headers="table.activeHeaders"
              :items="departmentList"
              :items-per-page="table.pageSize"
              :server-items-length="total"
              :footer-props="{'items-per-page-options': table.itemPerPages}"
              :sort-desc="true"
              :must-sort="false"
              class="elevation-1 custom-table"
              fixed-header
              style="width: 100%"
              :height="
                windowSize.y - heightOptions.header - heightOptions.tableFooter
              "
            >
              <template v-slot:item.content="{item}">
                <v-list-item-content
                  class="d-inline-block text-truncate"
                  style="width: 265px"
                >
                  <v-list-item-title>
                    {{ getContentByLang(item.contents, 'th') }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getContentByLang(item.contents, 'en') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:item.doctor_count="{item}">{{
                item.doctor_count
              }}</template>
              <template v-slot:item.org="{item}">{{
                listBranch(item.org)
              }}</template>
              <template v-slot:item.update_date="{item}">{{
                dateFmt(item.update_date)
              }}</template>
              <template v-slot:item.update_by_user="{item}">
                <username-status-text :user="item.update_by_user" />
              </template>
              <template v-slot:item.status="{item}" style="width: 82px">
                <v-switch
                  t-data="status-switch"
                  color="primary200Color"
                  v-model="item.status"
                  :false-value="inActiveStatus"
                  :true-value="activeStatus"
                  :disabled="!hasPermEditByOrgs(item.org)"
                  @change="event => handleSwitchStatusChange(event, item.id)"
                  class="mb-0 pb-0 custom-switch"
                ></v-switch>
              </template>
              <template v-slot:item.actions="{item}">
                <v-icon
                  t-data="preview-action"
                  class="px-2 action-icon"
                  @click="actionSchedule(item)"
                  >mdi-calendar-month</v-icon
                >
                <v-icon
                  t-data="preview-action"
                  class="px-2 action-icon"
                  @click="actionPreview(item)"
                  >mdi-magnify</v-icon
                >
                <v-icon
                  t-data="edit-action"
                  class="px-2 action-icon"
                  @click="actionEdit(item)"
                  >mdi-pencil-outline</v-icon
                >
                <v-icon
                  t-data="delete-action"
                  class="px-2 action-icon"
                  v-if="hasPermDeleteByOrgs(item.org)"
                  @click="actionDelete(item)"
                  >mdi-trash-can-outline</v-icon
                >
                <v-icon
                  v-else
                  t-data="empty-icon"
                  class="px-2 action-icon transparent-icon"
                ></v-icon>
              </template>
            </v-data-table>
          </v-layout>
          <div
            class="table-footer-prepend"
            t-data="total-result-content"
            v-if="isSelectedBranch"
          >
            <div class="vertical-center pl-4">
              <b>{{ total }}</b> Search results
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <sortable-table
            @updateSuccess="showSuccessSnackbar(actionEnumData.STATUS_UPDATED)"
            @updateFail="showFailSnackbar(actionEnumData.STATUS_UPDATED)"
          ></sortable-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import EditOrderDialog from '@/components/EditOrderDialog'
import {mapState, mapActions} from 'vuex'
import {
  convertDate,
  hasPermision,
  hasPermissionsByOrgs,
  searchHandler,
  snackbarFailContent,
  snackbarSuccessContent,
  swapArrayElements
} from '../../helper/helper'
import AlertConfirm from '../../components/AlertConfirm'
import Preview from '../../components/DepartmentPreview'
import SnackBar from '@/components/SnackBar'
import FilterDropdown from '@/views/department/FilterDropdown'
import Search from '@/components/Search'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import {departmentStatusEnum} from '@/constants/department'
import {actionEnum, tabEnum} from '@/constants/constants'
import PopupLoading from '@/components/PopupLoading'
import UsernameStatusText from '@/components/UsernameStatusText'
import SortableTable from '@/components/department/SortableTable'
import AddHighlightPopup from '@/views/department/AddHighlightPopup'
export default {
  created() {
    this.textSearch()
  },
  components: {
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    AlertConfirm,
    EditOrderDialog,
    FilterDropdown,
    Search,
    SingleSelectedBranch,
    SnackBar,
    SortableTable,
    UsernameStatusText,
    AddHighlightPopup
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
        this.setAllDepartment()
      },
      deep: true
    }
  },
  computed: {
    isSelectedBranch() {
      return this.filterHospital !== ''
    },
    showSearchAndFilter() {
      return this.isSelectedBranch && this.tabItems[this.tab] === tabEnum.LIST
    },
    tabItems() {
      return Object.values(tabEnum)
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.filterHospital !== '') {
        selectedBranch = this.filterHospital
      }
      return selectedBranch
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    ...mapState('department', [
      'isEditOrder',
      'departmentList',
      'departmentObject',
      'editOrderObject',
      'filterHospital',
      'isLoading',
      'isLoadingBranch',
      'orgObject',
      'sortableList',
      'total',
      'highlights'
    ])
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle
    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }

    this.setViewOrgInfo()
  },
  destroyed() {
    this.resetState()
    this.resetList()
    this.setFilterHospital('')
  },
  methods: {
    addHighLight() {
      this.showAddHighlightPopup = true
    },
    create() {
      this.$router.push({
        name: 'department-create'
      })
    },
    resetSearchAndFilterData() {
      this.options.page = 1
      this.componentKey++
      this.filters.searchPhase = ''
      this.resetSearchAndFilter()
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.resetSearchAndFilterData()
        this.setFilterHospital(value[0])
        this.fetchByTab(this.tab)
        this.setAllDepartment()
      }
    },
    async saveEditOrder(data) {
      this.setShowEditOrder(false)
      const {oldOrderNo, newOrderNo} = data
      const newOrderList = swapArrayElements(
        this.sortableList,
        oldOrderNo - 1,
        newOrderNo - 1
      )
      await this.reorderDepartmentsList(newOrderList)
      this.showSuccessSnackbar(actionEnum.ORDER_UPDATED)
    },
    async saveHighlight() {
      this.showAddHighlightPopup = false
      const ids = this.highlights.map(obj => obj.id)
      await this.updateHighlights(ids)
      this.showSuccessSnackbar('Updated Highlight')
    },
    async fetchByTab(data) {
      this.resetSearchAndFilterData()
      if (this.tabItems[data] === tabEnum.SORT) {
        await this.fetchSortableList()
      }
      if (this.tabItems[data] === tabEnum.LIST) {
        await this.fetch()
      }
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    closeEditOrder() {
      this.setShowEditOrder(false)
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    hasPermCreate() {
      return hasPermision('CanAddDepartment')
    },
    hasPermEdit() {
      return hasPermision('CanEditDepartment')
    },
    hasPermDelete() {
      return hasPermision('CanDeleteDepartment')
    },
    hasPermEditByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanEditDepartment', codenames)
    },
    hasPermDeleteByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanDeleteDepartment', codenames)
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    textSearch(data) {
      this.filters.searchPhase = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(
          this.filters.searchPhase ? this.filters.searchPhase.trim() : ''
        )
        this.options.page = 1
        await this.fetch()
      })
    },
    statusText(status) {
      return this.statusLookup[status]
    },
    statusColor(status) {
      return this.colorsLookup[status]
    },
    dateFmt: date => convertDate(date),
    getContentByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0].title
      }
      return ''
    },
    listBranch(orgs) {
      return orgs.map(org => org.org_codename.toUpperCase()).join(', ')
    },
    async handleSwitchStatusChange(event, id) {
      const isSuccess = await this.changeStatus({status: event, ids: [id]})
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
        this.setAllDepartment()
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    async actionPreview(item) {
      await this.setDepartmentId(item.id)
      this.isPreview = true
    },
    actionSchedule(item){
      this.$router.push({name: 'department-schedules', params: {id: item.id}})
    },
    closePreview() {
      this.isPreview = false
    },
    actionEdit(item) {
      this.resetState()
      this.$router.push({name: 'department-edit', params: {id: item.id}})
    },
    async actionDelete(item) {
      this.objectDelete = item
      this.deleteId = item.id
      this.isDelete = true
    },
    async confirmDialog() {
      this.setDeleteId(this.deleteId)
      const isSuccess = await this.deletePkg(this.deleteId)
      this.deleteId = ''
      this.isDelete = false
      if (isSuccess) {
        this.fetch()
        this.showSuccessSnackbar(actionEnum.REMOVED)
        this.setAllDepartment()
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDialog() {
      this.deleteId = ''
      this.setDeleteId(this.deleteId)
      this.isDelete = false
    },
    closeDeleteErrorDialog() {
      this.isDeleteError = false
    },
    ...mapActions('department', [
      'changeStatus',
      'deletePkg',
      'fetch',
      'fetchSortableList',
      'reorderDepartmentsList',
      'resetList',
      'resetSearchAndFilter',
      'resetState',
      'setDeleteId',
      'setDepartmentId',
      'setFieldSort',
      'setFilterHospital',
      'setPage',
      'setPageSize',
      'setShowEditOrder',
      'setSort',
      'setTextSearch',
      'setViewOrgInfo',
      'setAllDepartment',
      'updateHighlights'
    ])
  },

  data() {
    return {
      showAddHighlightPopup: false,
      pageName: 'department',
      heightOptions: {
        header: 420,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      componentKey: 0,
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: '',
      actionEnumData: actionEnum,
      isPreview: false,
      filters: {
        searchPhase: ''
      },
      activeStatus: departmentStatusEnum.ACTIVE,
      inActiveStatus: departmentStatusEnum.INACTIVE,
      options: {},
      hospitalRules: [v => !!v || 'Branch is required'],
      objectDelete: {},
      buttonConfirmText: 'Remove',
      subTitle:
        'This content will be deleted in both Thai and English.\nAre you sure you want to delete this content?',
      deleteId: '',
      isDelete: false,
      isDeleteError: false,
      colorsLookup: {
        0: 'green',
        1: 'red',
        2: 'yellow accent-4'
      },
      statusLookup: {
        0: 'Active',
        1: 'Inactive',
        2: 'Waiting'
      },
      tab: 0,
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        activeHeaders: [
          {
            text: 'Department Name',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 288
          },
          {
            text: 'Doctor',
            align: 'left',
            sortable: false,
            value: 'doctor_count',
            width: 137
          },
          {
            text: 'Branch',
            align: 'left',
            sortable: false,
            value: 'org',
            width: 110
          },
          {
            text: 'Updated date/time',
            align: 'left',
            sortable: false,
            value: 'update_date',
            width: 190
          },
          {
            text: 'Updated By',
            align: 'left',
            sortable: false,
            value: 'update_by_user',
            width: 162
          },
          {
            text: 'Status',
            align: 'center',
            sortable: false,
            value: 'status',
            width: 100
          },
          {
            text: 'Actions',
            align: 'center',
            sortable: false,
            value: 'actions',
            width: 160
          }
        ]
      },
      breadcrumbs: [
        {text: 'Department Management', disabled: true, link: false},
        {
          text: 'Departments',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
